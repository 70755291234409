import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as $Student from '@services/Users/Student'
import * as $School from '@services/School'
import * as $Degree from '@services/Degree'
import * as Utils from '@helpers/Utils'
import IUser from '@interfaces/IUser'
import axios from 'axios'
import IDegree from '@interfaces/IDegree'
import IClass from '@interfaces/IClass'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '@components/Pagination/Pagination'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableStudentColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Delete from './Delete/Delete'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import useUpdateEffect from '@hooks/useUpdateEffect'
import { Search, Buttons } from './Students.styles'
import Password from '@components/Users/Password/Password'

const Students: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ years, setYears ] = useState<number[]>([ 2023 ])
  const [ students, setStudents ] = useState<IUser[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ search, setSearch ] = useState<string>('')
  const [ userCode, setUserCode ] = useState<number|null>(null)
  const [ identifier, setIdentifier ] = useState<number|null>(null)
  const [ year, setYear ] = useState<number>(2023)
  const [ degreeId, setDegreeId ] = useState<number|null>(null)
  const [ classId, setClassId ] = useState<number|null>(null)
  const [ schools, setSchools ] = useState<any[]>([])
  const [ schoolId, setSchoolId ] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setIsLoading(true)

      $School.all().then(({ data }: any) => {
        setSchools(data)
      }).finally(() => setIsLoading(false))
    }
  }, [client])

  useUpdateEffect(() => {
    if (schoolId) {
      setDegreeId(null)
      setClassId(null)

      axios.all([
        $Degree.all(schoolId, year),
        $Student.years(schoolId),
      ]).then(axios.spread(({ data: degrees }: any, { data: years }: any) => {
        setDegrees(degrees)
        setYears(years)
      }))
    }
  }, [schoolId, year])

  const onSearch = useCallback(() => {
    setIsLoading(true)

    $Student.all(schoolId, {
      year,
      degreeId,
      classId,
      search,
      userCode,
      identifier,
    }).then(({ data }: any) => setStudents(data)).finally(() => setIsLoading(false))
  }, [classId, degreeId, identifier, schoolId, search, userCode, year])

  const handleOnDelete = (studentId: number) => setStudents(students => students.filter(student => student.id !== studentId))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: students,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const degree = useMemo(() => degrees?.find(degree => degree.id === degreeId), [degrees, degreeId])

  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const exportStudents = () => {
    const data = students.map((student: IUser) => ([
      student.name,
      student.email,
      student.identifier,
      student.usercode,
      student.degreeId,
      student.className,
      student.password && student.password?.length > 20 ? 'Senha alterada' : student.password,
    ] as string[]))

    data.unshift([
      'Nome', 'Email', 'RA', 'RE', 'Série', 'Turma', 'Senha',
    ])

    Utils.exportXLSX(data, 'Alunos', 'Alunos.xlsx')
  }

  const options = useMemo(() => [{
    value: 0,
    label: 'Todas'
  }, ...schools.map((school: ISchool) => ({
    value: school.id,
    label: school.name,
  }))], [schools])

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-8">
          <Search>
            <div className="row">
              {options.length > 0 && (
                <div className={'col-8 form-group mb-3 schools ' + (degrees.length ? 'col-md-6' : 'col-md-3')}>
                  <label htmlFor="search">Escola:</label>
                  <div className="select-schools">
                    <Select
                      type="primary"
                      onChange={handleSchoolIdChange}
                      defaultValue={0}
                      options={options}
                      isSearchable={true}
                      placeholder="Selecione uma escola"
                    />
                  </div>
                </div>
              )}

              {years.length > 0 && (
                <div className="col-4 col-md-2 form-group mb-3 years">
                  <label htmlFor="years">Ano:</label>
                  <select id="year" name="year" className="form-control" defaultValue={year} onChange={e => setYear(parseInt(e.target.value))}>
                    {years.map((year: number) => (
                      <option value={year} key={year}>{year}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className="col-6 col-md-2 form-group mb-3 identifier">
                <label htmlFor="identifier">RA:</label>
                <input
                  type="text"
                  id="identifier"
                  name="identifier"
                  className="form-control"
                  onChange={e => setIdentifier(e.target.value.length > 0 ? parseInt(e.target.value) : null)}
                />
              </div>

              <div className="col-6 col-md-2 form-group mb-3 userCode">
                <label htmlFor="userCode">RE:</label>
                <input
                  type="text"
                  id="userCode"
                  name="userCode"
                  className="form-control"
                  onChange={e => setUserCode(e.target.value.length > 0 ? parseInt(e.target.value) : null)}
                />
              </div>

              <div className={'col-12 form-group mb-3 search ' + (degrees.length ? (degree ? 'col-md-6' : 'col-md-9') : 'col-md-3')}>
                <label htmlFor="search">Pesquisar:</label>
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="form-control"
                  placeholder="Nome ou e-mail"
                  onChange={e => setSearch(e.target.value)}
                />
              </div>

              {degrees.length > 0 && (
                <div className="col-6 col-md-3 form-group mb-3 degrees">
                  <label htmlFor="degreeId">Série:</label>
                  <select
                    id="degreeId"
                    name="degreeId"
                    className="form-control"
                    onChange={e => {
                      setDegreeId(e.target.value.length > 0 ? parseInt(e.target.value) : null)
                      setClassId(null)
                    }}
                  >
                    <option value="">Todas</option>
                    {degrees.map((degree: IDegree) => (
                      <option value={degree.id} key={degree.id}>{degree.name}</option>
                    ))}
                  </select>
                </div>
              )}

              {degree && degree?.classes?.length > 0 && (
                <div className="col-6 col-md-3 form-group mb-3 classes">
                  <label htmlFor="className">Turma:</label>
                  <select id="className" name="className" className="form-control" onChange={e => setClassId(e.target.value.length > 0 ? parseInt(e.target.value) : null)}>
                    <option value="">Todas</option>
                    {degree.classes.map((class_: IClass) => (
                      <option value={class_.id} key={class_.id}>{class_.name}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <button className="btn btn-primary mb-3" onClick={onSearch}>Pesquisar</button>
          </Search>
        </div>

        <Buttons className="col-12 col-md-4 d-flex justify-content-end">
          <button className="btn btn-outline-primary mb-3" onClick={exportStudents}>Exportar</button>
          <button className="btn btn-primary ms-2 mb-3" onClick={() => navigate('/alunos/cadastrar')}>Cadastrar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Alunos</div>

        <div className="card-body p-0">
          {!isLoading ? (students.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 120 }}>Senha</th>
                      <th style={{ width: 120 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              <span className="text-overflow">{cell.render('Cell')}</span>
                            </td>
                          )
                        })}

                        <td style={{ verticalAlign: 'middle' }}>
                          <Password userId={row.original.id} password={row.original.password} disabled={!row.original?.email?.length} />
                        </td>

                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-end">
                            <Link to={`/alunos/${row.original.schoolId}/${row.original.id}/editar/${year}`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                            {row.original.accessCount == 0 && (
                              <Delete year={year} student={row.original} onDelete={handleOnDelete} />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Selecione uma escola e/ou forneca alguma informação sobre o aluno e clique em pesquisar para que os resultados sejam exibidos.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {students.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Students
