import React, { useContext, useRef } from 'react'
import ReportmentContext from '@contexts/Reportment'
import IChart from '@interfaces/IChart'
import { Modal } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Chart from '@services/Chart'
import Pivot from '../Pivot/Pivot'

const Chart: React.FC<any> = () => {
  const { reportment, charts, setCharts, chart, setChart } = useContext(ReportmentContext)

  const chartRef = useRef<any>(null)
  const SweetAlert = withReactContent(Swal)

  const onUpdate = (slice: any, options: any) => setChart({
    ...chart,
    slice,
    options,
  } as IChart)

  const onHide = () => setChart(null)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (chart === null)
      return

    SweetAlert.fire({
      title: 'Aguarde...',
      text: chart.id > 0 ? 'Atualizando gráfico...' : 'Registrando gráfico...',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(event.currentTarget)

    formData.append('reportmentId', reportment.id.toString())
    formData.append('slice', JSON.stringify(chart.slice))
    formData.append('options', JSON.stringify(chart.options))

    if (chart.id > 0) {
      $Chart.update(reportment.id, chart.id, formData).then(({ data: chart }: any) => {
        const index = charts.findIndex(({ id }) => id === chart.id)

        charts[index] = chart

        setCharts([
          ...charts,
        ])

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Gráfico atualizado com sucesso.',
          icon: 'success',
        })
      })
    } else {
      $Chart.store(reportment.id, formData).then(({ data: chart }: any) => {
        setCharts([
          ...charts,
          chart,
        ])

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Gráfico registrado com sucesso.',
          icon: 'success',
        }).then(() => onHide())
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao registrar o gráfico.',
          icon: 'error',
        })
      })
    }
  }

  const onRefresh = () => {
    if (chartRef.current !== null)
      chartRef.current.refresh()
  }

  return (
    <Modal show={!!chart} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{chart && chart.name.length > 0 ? chart.name : 'Novo gráfico'}</Modal.Title>
      </Modal.Header>

      <form onSubmit={onSubmit}>
        <Modal.Body>
          {chart && <Pivot ref={chartRef} onUpdate={onUpdate} />}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-between">
          <button type="button" className="btn btn-outline-primary" onClick={onRefresh}>Recarregar flexmonster</button>

          <button type="submit" className="btn btn-primary">
            {chart && chart.id > 0 ? 'Atualizar' : 'Salvar'}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Chart
