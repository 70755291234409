import axios from 'axios'

export const all = (params: any = {}) : Promise<any> => {
  return axios.get('schools', {
    params,
  })
}

export const findByClient = (clientId: number): Promise<any> => {
  return axios.get(`schools/${clientId}`)
}
