import axios from 'axios'

export const all = (): Promise<any> => {
  return axios.get('/reportments')
}

export const managers = (clientId: number, schoolId: number): Promise<any> => {
  return axios.get('/reportments/managers', {
    params: {
      clientId,
      schoolId,
    },
  })
}

export const find = (reportmentId: number): Promise<any> => {
  return axios.get(`reportments/${reportmentId}`)
}

export const update = (reportmentId: number, reportment: FormData): Promise<any> => {
  return axios.put('reportments/' + reportmentId, reportment, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const store = (reportment: FormData): Promise<any> => {
  return axios.post('reportments/', reportment, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const remove = (reportmentId: number): Promise<any> => {
  return axios.delete(`reportments/${reportmentId}`)
}

export const undelete = (reportmentId: number): Promise<any> => {
  return axios.post(`reportments/${reportmentId}/undelete`)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const preview = (params: FormData): Promise<any> => {
  return axios.post('reportments/preview', params)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const execute = (params: any): Promise<any> => {
  return axios.post('reportments/execute', params)
}
