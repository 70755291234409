import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import IReportment from '@interfaces/IReportment'
import * as $Reportment from '@services/Reportment'
import * as $ReportmentCategory from '@services/ReportmentCategory'
import * as $Platform from '@services/Platform'
import * as $Client from '@services/Client'
import * as $Chart from '@services/Chart'
import axios from 'axios'
import IPlatform from '@interfaces/IPlatform'
import IReportmentSchool from '@interfaces/IReportmentSchool'
import IChart from '@interfaces/IChart'

type ReportmentContextProps = {
  data: string[][]
  setData: React.Dispatch<React.SetStateAction<string[][]>>
  clients: any[]
  setClients: React.Dispatch<React.SetStateAction<any[]>>
  reportment: IReportment
  setReportment: React.Dispatch<React.SetStateAction<IReportment>>
  showAssociation: boolean
  setShowAssociation: React.Dispatch<React.SetStateAction<boolean>>
  showPreviewModal: boolean
  setShowPreviewModal: React.Dispatch<React.SetStateAction<boolean>>
  charts: IChart[]
  setCharts: React.Dispatch<React.SetStateAction<IChart[]>>
  chart: IChart|null
  setChart: React.Dispatch<React.SetStateAction<IChart|null>>
  platforms: IPlatform[]
  categories: any[]
  isLoading: boolean
}

const ReportmentContext = createContext<ReportmentContextProps>({ } as ReportmentContextProps)

export const ReportmentProvider: React.FC<any> = () => {
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ categories, setCategories ] = useState<any[]>([])
  const [ showAssociation, setShowAssociation ] = useState<boolean>(false)
  const [ showPreviewModal, setShowPreviewModal ] = useState<boolean>(false)
  const [ reportment, setReportment ] = useState<IReportment>({
    id: 0,
    name: '',
    description: '',
    reportmentSchools: [] as IReportmentSchool[],
  } as IReportment)
  const [ data, setData ] = useState<string[][]>([] as any)
  const [ clients, setClients ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ chart, setChart ] = useState<IChart|null>(null)
  const [ charts, setCharts ] = useState<IChart[]>([] as IChart[])

  const { id: reportmentId }: any = useParams()

  useEffect(() => {
    const $requests = [
      $Platform.all(),
      $ReportmentCategory.all(),
      $Client.all(),
    ]

    if (reportmentId) {
      $requests.push($Reportment.find(reportmentId))
      $requests.push($Chart.all(reportmentId))
    }

    axios.all($requests).then(axios.spread(({ data: platforms }: any, { data: categories }: any, { data: clients }: any, ...responses) => {
      setPlatforms(platforms)
      setCategories(categories)
      setClients(clients)

      if (reportmentId) {
        setReportment(responses[0].data)
        setCharts(responses[1].data)
      }
    })).finally(() => setIsLoading(false))
  }, [reportmentId])

  return (
    <ReportmentContext.Provider value={{
      data,
      setData,
      clients,
      setClients,
      reportment,
      setReportment,
      showAssociation,
      setShowAssociation,
      showPreviewModal,
      setShowPreviewModal,
      charts,
      setCharts,
      chart,
      setChart,
      platforms,
      categories,
      isLoading,
    }}
    >
      <Outlet />
    </ReportmentContext.Provider>
  )
}

export default ReportmentContext
