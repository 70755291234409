import React from 'react'
import ReactSelect from 'react-select'

const selectSolid = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    fontSize: state.selectProps.myFontSize
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: state.data.color,
    fontSize: state.selectProps.myFontSize
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#000000'
  })
}

const selectOutline = {
  placeholder: (base: any) => ({
    ...base,
    '&:hover': {
      borderColor: 'white'
    },
    color: 'white',
    boxShadow: null
  }),
  menuList: (base: any) => ({
    ...base,
    '&:hover': {
      borderColor: 'white'
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: state.selectProps.myFontSize
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: state.data.color,
    fontSize: state.selectProps.myFontSize
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    '&:hover': {
      color: 'white'
    },
    color: 'white',
    opacity: 1
  }),
  control: (base: any) => ({
    ...base,
    '&:hover': {
      borderColor: 'white',
      boxShadow: '0 0 0 1px white'
    },
    borderColor: 'white',
    boxShadow: '0 0 0 1px white',
    backgroundColor: 'transparent',
    color: 'white'
  }),
  input: (base: any) => ({
    ...base,
    color: 'white'
  })
}

const Select: any = (props: any) => {
  return (
    <ReactSelect
      styles={props.type === 'outline' ? selectOutline : selectSolid}
      components={{IndicatorSeparator: () => null}}
      {...props}
    />
  )
}

export default Select
