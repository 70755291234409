import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableClientsColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Pagination from '@components/Pagination/Pagination'
import * as $Client from '@services/Client'
import IClient from '@interfaces/IClient'

const Clients: React.FC<any> = () => {

  const [ clients, setClients ] = useState<IClient[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ search, setSearch ] = useState<string>('')
  const [ active, setActive ] = useState<string>('')
  const [ canEdit, setCanEdit ] = useState<string>('')

  useEffect(() => {
    $Client.all().then(({ data }: any) => setClients(data)).finally(() => setIsLoading(false))
  }, [])

  const data = useMemo(() => {
    return clients.filter((client: IClient) => {
      let exists = true

      if (search.length > 0)
        exists = client.id.toString().includes(search) || client.name.toLowerCase().includes(search.toLowerCase())

      if (exists && active.length > 0)
        exists = client.active.toString().toLowerCase().includes(active == '1' ? 'true' : 'false')

      if (exists && canEdit.length > 0)
        exists = client.canEdit.toString().toLowerCase().includes(canEdit == '1' ? 'true' : 'false')

      return exists
    })
  }, [ clients, search, active, canEdit ])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-12 col-md-7">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Ativo:</label>
                <select className="form-control" onChange={(e: any) => setActive(e.target.value)}>
                  <option value="">Todos</option>
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Editável:</label>
                <select className="form-control" onChange={(e: any) => setCanEdit(e.target.value)}>
                  <option value="">Todos</option>
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo id ou nome" onChange={e => setSearch(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Clientes</div>
        <div className="p-0">
          <div className="row">
            {!isLoading ? (
              <div className="col-12">
                <table {...getTableProps()} className="table table-default mb-0">
                  <thead>
                    {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                        {headerGroup.headers.map((column: any, columnIndex: number) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                            {column.render('Header')}
                            <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {clients.length > 0 ? page.map((item: any, itemIndex: number) => {
                      prepareRow(item)
                      return (
                        <tr key={itemIndex}>
                          <td>{item.values.id}</td>
                          <td>{item.values.name}</td>
                          <td>{item.values.active ? 'Sim' : 'Não'}</td>
                          <td>{item.values.canEdit ? 'Sim' : 'Não'}</td>
                          <td><Link to={`/clientes/${item.values.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link></td>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={5} style={{textAlign: 'center'}}>Nenhum resultado encontrado.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="d-flex justify-content-center p-3">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
          </div>
        </div>
        {!isLoading && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-between align-items-center">
            <div>
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Clients
