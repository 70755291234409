import React, { useContext } from 'react'
import * as $ReportmentCategory from '@services/ReportmentCategory'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import useReportmentCategoryColumns from '@hooks/useReportmentCategoryColumns'
import ReportmentCategory from './ReportmentCategory/ReportmentCategory'
import ReportmentCategoryContext from '@contexts/ReportmentCategory'
import { List } from './ReportmentCategories.styles'

const ReportmentCategories: React.FC<any> = () => {
  const { category, categories, setCategories, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(ReportmentCategoryContext)

  const onDelete = (category: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $ReportmentCategory.destroy(category.id).then(({ data: categories }: any) => setCategories(categories)).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useReportmentCategoryColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: categories,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Categorias de relatórios</h1>
        <button className="btn btn-primary pe-3 ps-3" onClick={() => onClick(null)} disabled={!canEdit}>Nova categoria</button>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!category ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Categorias</div>

            <div className="card-body p-0">
              <List className="table-responsive">
                <Table
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  isLoading={isLoading}
                />
              </List>
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        <ReportmentCategory />
      </div>
    </>
  )
}

export default ReportmentCategories
