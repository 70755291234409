import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTablePossibleErrorColumns'
import Pagination from '@components/Pagination/Pagination'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Select from '@components/Select/Select'
import ValidatePossibleError from '@components/ValidatePossibleError/ValidatePossibleError'
import PossibleErrorContext from '@contexts/PossibleError'

const PossibleErrors: React.FC<any> = () => {
  const { possibleErrors, schools, evaluations,
    isLoading, handleModal, setAmount } = useContext(PossibleErrorContext)

  const [ search, setSearch ] = useState<string>('')
  const [ evaluationId, setEvaluationId ] = useState<number>(0)
  const [ schoolId, setSchoolId ] = useState<number>(0)

  const data = useMemo(() => possibleErrors.filter((possible: any) => {
    let exists = true

    if (search.length > 0)
      exists = possible.id.toString().includes(search) ||
        possible.barCode.toLowerCase().includes(search.toLowerCase()) ||
        possible.answers.toLowerCase().includes(search.toLowerCase())

    if (exists && evaluationId > 0)
      exists = possible.evaluationId === evaluationId

    if (exists && schoolId > 0)
      exists = possible.schoolId === schoolId

    return exists
  }), [possibleErrors, search, evaluationId, schoolId])

  useEffect(() => {
    setAmount(data.length)
  }, [data, setAmount])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const evaluationOptions = useMemo(() => [{
    value: 0,
    label: 'Todas'
  }, ...evaluations.map((evaluation: any) => ({
    value: evaluation.evaluationId,
    label: evaluation.evaluationName,
  }))], [evaluations])

  const schoolOptions = useMemo(() => [{
    value: 0,
    label: 'Todas'
  }, ...schools.map((school: any) => ({
    value: school.schoolId,
    label: school.schoolName,
  }))], [schools])

  const handleEvaluationIdChange = ({ value }: any) => setEvaluationId(value)
  const handleSchoolIdChange = ({ value }: any) => setSchoolId(value)

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-3 col-md-3">
          {evaluationOptions.length > 0 && (
            <div className="form-group evaluations">
              <label htmlFor="search">Prova:</label>
              <div className="select-schools">
                <Select
                  type="primary"
                  onChange={handleEvaluationIdChange}
                  defaultValue={0}
                  options={evaluationOptions}
                  isSearchable={true}
                  disabled={isLoading}
                  placeholder="Selecione uma prova"
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-3 col-md-3">
          {schoolOptions.length > 0 && (
            <div className="form-group schools">
              <label htmlFor="search">Escola:</label>
              <div className="select-schools">
                <Select
                  type="primary"
                  onChange={handleSchoolIdChange}
                  defaultValue={0}
                  options={schoolOptions}
                  isSearchable={true}
                  disabled={isLoading}
                  placeholder="Selecione uma escola"
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-3 col-md-3">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo ID, respostas ou código de barras" onChange={e => setSearch(e.target.value)} disabled={isLoading} />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Possíveis erros</div>
        <div className="card-body p-0">
          {!isLoading ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 200 }}>Ações</th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {possibleErrors?.length > 0 ? page?.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td>
                          <button className="btn btn-sm ps-4 pe-4 btn-primary ms-2" onClick={() => handleModal(row)}>Visualizar</button>
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan={6} className="text-center">
                        <span className="text-muted">Nenhuma associação encontrada</span>
                      </td>
                    </tr> }
                </tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}

        <ValidatePossibleError />
      </div>
    </>
  )
}

export default PossibleErrors
