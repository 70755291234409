import React, { useCallback, useContext, useMemo, useState } from 'react'
import * as $ReportmentAudit from '@services/ReportmentAudit'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableIReportmentAuditColumns'
import Pagination from '@components/Pagination/Pagination'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import IReportmentAudit from '@interfaces/IReportmentAudit'
import ReportmentAuditContext from '@contexts/ReportmentAudit'
import ReportmentAudit from '@components/ReportmentAudit/ReportmentAudit'
import { Buttons } from './ReportmentAudits.styles'

const ReportmentAudits: React.FC<any> = () => {
  const { setReportment, setShowModal, setIsLoadingModal } = useContext(ReportmentAuditContext)
  const [ reportments, setReportments ] = useState<IReportmentAudit[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ search, setSearch ] = useState<string>('')
  const [ assessmentId, setAssessmentId ] = useState<number>(0)

  const onSearch = useCallback(() => {
    setIsLoading(true)
    $ReportmentAudit.all(assessmentId).then(({ data }: any) => {
      setReportments(data)
    }).finally(() => setIsLoading(false))
  }, [assessmentId])

  const data = useMemo(() => reportments.filter((reportment: any) => {
    let exists = true

    if (search.length > 0)
      exists = reportment.schoolId.toString().includes(search) ||
        reportment.fantasyName.toLowerCase().includes(search.toLowerCase()) ||
        reportment.assessmentID.toString().includes(search) ||
        reportment.assessmentName.toLowerCase().includes(search.toLowerCase())

    return exists
  }), [reportments, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const showModal = (reportment: IReportmentAudit, type: number) => {
    setIsLoadingModal(true)
    setShowModal(true)
    setReportment({
      ...reportment,
      type: type
    } as IReportmentAudit)
  }

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-3 mb-3">
          <div className="form-group">
            <label htmlFor="assessmentId">ID simulado:</label>
            <input
              type="number"
              id="assessmentId"
              name="search"
              className="form-control"
              placeholder="Insira o ID do simulado"
              onChange={e => setAssessmentId(e.target.value.length > 0 ? parseInt(e.target.value) : 0)}
              disabled={isLoading}
            />
          </div>
        </div>
        { reportments.length > 0 ? (
          <div className="col-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="search">Pesquisar:</label>
              <input
                type="text"
                id="search"
                name="search"
                className="form-control"
                placeholder="Pesquise pelo ID ou nome"
                onChange={e => setSearch(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
        ): null }
        <div className="col-12 col-md-3 mb-3">
          <Buttons className="form-group">
            <button
              className="btn btn-primary"
              onClick={onSearch}
              disabled={isLoading}
            >Pesquisar</button>
          </Buttons>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Relatório de auditorias e erros</div>
        <div className="card-body p-0">
          {!isLoading ? (reportments.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.length > 0 ? page?.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              { cell.column.id === 'amountAudit' ?
                                <button
                                  onClick={() => showModal(row.original, 1)}
                                  className="btn btn-outline-secondary"
                                  title="Visualizar auditorias"
                                  disabled={row.original.amountAudit === 0 ? true : false}
                                >
                                  {cell.render('Cell')}</button>
                                : cell.column.id === 'amountError' ?
                                  <button onClick={() => showModal(row.original, 2)}
                                    className="btn btn-outline-danger"
                                    title="Visualizar erros"
                                    disabled={row.original.amountError === 0 ? true : false}
                                  >
                                    {cell.render('Cell')}</button>
                                  : cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colSpan={5} className="text-center">
                        <span className="text-muted">Nenhuma escola encontrada</span>
                      </td>
                    </tr> }
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Insira um ID de simulado e clique em pesquisar para que os resultados sejam exibidos.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>

      <ReportmentAudit />
    </>
  )
}

export default ReportmentAudits
