import React, { useCallback, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $Tools from '@services/Tools'
import { getCssProperty } from '@helpers/Theme'
import * as Utils from '@helpers/Utils'
import { Modal } from 'react-bootstrap'

const Tools: React.FC<any> = () => {
  const [ modalRepormentStatus, setModalRepormentStatus ] = useState<boolean>(false)
  const [ assessmentId, setAssessmentId ] = useState<number>(0)
  const [ startDate, setStartDate ] = useState<string>('')
  const [ endDate, setEndDate ] = useState<string>('')
  const SweetAlert = withReactContent(Swal)

  const [ modalAudit, setModalAudit ] = useState<boolean>(false)
  const [ answerCardAuditId, setAnswerCardAuditId ] = useState<number>(0)

  const migrationForce = useCallback(() => {
    SweetAlert.showLoading()

    $Tools.migrationForce().then((migration: any) => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Migrações forçadas com sucesso!',
        icon: 'success',
        allowOutsideClick: false,
        showCancelButton: true,
        showConfirmButton: migration.data.length > 0 ? true : false,
        confirmButtonColor: getCssProperty('--primary-color'),
        cancelButtonColor: getCssProperty('--secondary-color'),
        confirmButtonText: 'Download',
        cancelButtonText: 'Finalizar',
      }).then((result: any) => {
        if (result.value) {
          const data = migration.data.map((migration: any) => [
            migration.fantasyName,
            migration.userCode,
            migration.name,
            migration.evaluationName,
            migration.barcode
          ])

          data.unshift([
            'Nome Escola', 'RE', 'Aluno', 'Prova', 'BarCode',
          ])

          Utils.exportXLSX(data as string[][], 'Migrações Forçadas', 'Migracoes.xlsx')
        }
      })
    }).catch(() => SweetAlert.fire({
      title: 'Erro!',
      text: 'Erro ao forçar migrações!',
      icon: 'error'
    })).finally(() => SweetAlert.hideLoading())
  }, [SweetAlert])

  const statusReportment = () => {
    setAssessmentId(0)
    setModalRepormentStatus(true)
    setStartDate('')
    setEndDate('')
  }

  const submitStatusReportment = () => {
    if (Number(assessmentId ?? 0) == 0 && Number(startDate?.trim().length ?? 0) == 0 && Number(endDate?.trim().length ?? 0) == 0)
      SweetAlert.showValidationMessage('Insira pelo menos um parâmetro')
    else
      $Tools.statusReportment(assessmentId ?? 0, {
        DateStart: startDate,
        DateEnd: endDate
      }).then((data: any) => {
        const status = data.data.map((reportment: any) => [
          reportment.schoolID?.toString() ?? '',
          reportment.fantasyName ?? 'Não possui',
          reportment.evaluationID?.toString() ?? '',
          reportment.evaluationName ?? 'Não possui',
          reportment.evaluationDate?.toLocaleString() ?? 'Não possui',
          reportment.assessmentID?.toString() ?? '',
          reportment.statusName ?? 'Não possui',
          reportment.dateStatus?.toLocaleString() ?? 'Não possui',
          reportment.adminName ?? 'Não possui',
          reportment.firstAnswerDate?.toLocaleString() ?? 'Não possui'
        ])

        status.unshift([
          'ID Escola', 'Nome Escola', 'ID Prova', 'Nome Prova', 'Data Prova', 'ID Simulado', 'Status',
          'Data Status', 'Usuário', 'Data primeira resposta',
        ])
        Utils.exportXLSX(status as string[][], 'Relatorios', 'RelatorioStatus.xlsx')

        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Relatório gerado com sucesso!',
          icon: 'success'
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao gerar o relatório!',
          icon: 'error'
        })
      }).finally(() => {
        SweetAlert.hideLoading()
      })
  }

  const audit = () => {
    setModalAudit(true)
    setAnswerCardAuditId(0)
  }

  const submitAudit = () => {
    $Tools.audit(answerCardAuditId).then(() => {
      setModalAudit(false)
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Auditoria atualizada com sucesso!',
        icon: 'success'
      })
    }).catch(() => {
      setModalAudit(false)
      SweetAlert.fire({
        title: 'Erro!',
        text: 'Erro ao atualizar auditoria!',
        icon: 'error'
      })
    }).finally(() => {
      SweetAlert.hideLoading()
    })
  }

  return (
    <>
      <Modal
        show={modalRepormentStatus}
        onHide={() => setModalRepormentStatus(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações para gerar relatório</Modal.Title>
        </Modal.Header>
        <form onSubmit={submitStatusReportment} onReset={() => setModalRepormentStatus(false)}>
          <Modal.Body>
            <div className="d-flex flex-column text-center">
              <label>ID simulado</label>
              <input className="form-control mb-3" name="assessmentId" type="number"
                onChange={(e: any) => setAssessmentId(e.target.value)}
              />
              <label>De</label>
              <input className="form-control mb-3" name="startDate" type="date"
                onChange={(e: any) => setStartDate(e.target.value)}
              />
              <label>Para</label>
              <input className="form-control mb-3" name="endDate" type="date"
                onChange={(e: any) => setEndDate(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-12 d-flex justify-content-between">
              <button className="btn btn-outline-primary" type="reset">Cancelar</button>
              <button className="btn btn-primary" type="submit" disabled={assessmentId == 0 && !startDate.length && !endDate.length}>Solicitar</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={modalAudit}
        onHide={() => setModalAudit(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Resetar auditoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column text-center">
            <label>ID auditoria</label>
            <input className="form-control mb-3" name="answerCardAuditId" type="number"
              onChange={(e: any) => setAnswerCardAuditId(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 d-flex justify-content-between">
            <button className="btn btn-outline-primary" onClick={() => setModalAudit(false)}>Cancelar</button>
            <button className="btn btn-primary" onClick={submitAudit} disabled={answerCardAuditId == 0}>Solicitar</button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header">Ferramentas</div>
        <div className="card-body p-3">
          <div className="row">
            <div className="col-12 col-md-3 mt-2">
              <button className="btn btn-primary" onClick={migrationForce}>Forçar migração</button>
            </div>
            <div className="col-12 col-md-3 mt-2">
              <button className="btn btn-primary" onClick={statusReportment}>Relatório status</button>
            </div>
            <div className="col-12 col-md-3 mt-2">
              <button className="btn btn-primary" onClick={audit}>Liberar auditoria</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tools
