import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import * as Utils from '@helpers/Utils'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import * as $Manager from '@services/Users/Manager'
import columns from '@data/ReactTableAcademicColumns'
import Password from '@components/Users/Password/Password'
import Delete from '@components/Users/Delete/Delete'
import IManager from '@interfaces/Users/IManager'
import * as $Auth from '@services/Auth'
import * as $School from '@services/School'
import * as $Platform from '@services/Platform'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import AuthContext from '@contexts/Auth'
import IPlatform from '@interfaces/IPlatform'
import axios from 'axios'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import useUpdateEffect from '@hooks/useUpdateEffect'
import { Buttons } from './Managers.styles'

const Managers: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ managers, setManagers ] = useState<IManager[]>([] as IManager[])
  const [ schools, setSchools ] = useState<any[]>([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ search, setSearch ] = useState<string>('')
  const [ schoolId, setSchoolId ] = useState<number>(0)
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setManagers([])
      setIsLoading(true)

      axios.all([
        $School.all(),
        $Platform.all(),
      ]).then(axios.spread(({ data: schools }: any, { data: platforms }: any) => {
        setSchools(schools)
        setPlatforms(platforms)
      })).finally(() => setIsLoading(false))
    }
  }, [client])

  useEffect(() => {
    if (client?.id === 4 && !schoolId) {
      setSchoolId(297658)
    }
  }, [client?.id, schoolId])

  useUpdateEffect(() => {
    if (schoolId) {
      setIsLoading(true)
      $Manager.all(schoolId).then(({ data: managers }: any) => setManagers(managers)).finally(() => setIsLoading(false))
    }
  }, [schoolId])

  const data = useMemo(() => managers.filter((manager: IManager) => (
    manager.name.toLowerCase().includes(search.toLowerCase()) ||
    manager.nickname.toLowerCase().includes(search.toLowerCase()) ||
    manager.email.includes(search)
  )), [managers, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportManagers = () => {
    const data = managers.map((manager: IManager) => [
      manager.nickname,
      manager.name,
      manager.email,
      manager.password && manager.password?.length > 20 ? 'Senha alterada' : manager.password,
    ])

    data.unshift([
      'Apelido', 'Nome', 'Email', 'Senha',
    ])

    Utils.exportXLSX(data as string[][], 'Gestores', 'Gestores.xlsx')
  }

  const handleOnDelete = (managerId: number) => {
    setManagers(managers => managers.filter(manager => manager.id !== managerId))
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)

  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const platformRedirect = (manager: IManager, platform: IPlatform) => {
    SweetAlert.fire({
      title: 'Atenção',
      text: `Deseja ser redirecionado como gestor "${manager.name}" para a plataforma ${platform.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value) {
        SweetAlert.fire({
          title: 'Aguarde',
          text: 'Redirecionando...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          icon: 'info',
          showConfirmButton: false,
          didOpen: () => SweetAlert.showLoading()
        })

        $Auth.platformRedirect(platform.id, manager.id, manager.schoolId).then(({ data }: any) => {
          if (data?.ResultAccessUrl) {
            window.location.href = data.ResultAccessUrl[0]
          } else {
            window.location.href = data.redirectUrl
          }

          SweetAlert.close()
        }).catch(() => {
          SweetAlert.fire({
            title: 'Erro',
            text: 'Não foi possível redirecionar. Tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            didOpen: () => SweetAlert.hideLoading()
          })
        })
      }
    })
  }

  const options = useMemo(() => schools.map((school: ISchool) => ({
    value: school.id,
    label: school.name,
  })), [schools])

  const SelectComponent = useCallback(() => (
    <Select
      type="primary"
      onChange={handleSchoolIdChange}
      defaultValue={options.find(option => option.value === 297658)}
      options={options}
      isSearchable={true}
      placeholder="Selecione uma escola"
    />
  ), [options])

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-md-6">
          <div className="row">
            {options.length > 0 && (
              <div className="col-12 col-md-6 mb-3">
                <div className="form-group">
                  <label htmlFor="search">Escola:</label>
                  <div className="select-schools">
                    <SelectComponent />
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-md-6 mb-3">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise por nome ou e-mail" onChange={handleSearchChange} />
              </div>
            </div>
          </div>
        </div>

        <Buttons className="col-12 col-md-6 mb-3 d-flex justify-content-end align-items-end">
          <button className="btn btn-outline-primary me-2" onClick={exportManagers}>Exportar</button>
          <button className="btn btn-primary" onClick={() => navigate('/gestores/cadastrar')}>Cadastrar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Gestores ({managers.length})</div>

        <div className="card-body p-0">
          {!isLoading ? (data.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 140 }}>Acessar Como</th>
                      <th style={{ width: 120 }}>Senha</th>
                      <th style={{ width: 120 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              className="btn-sm pe-3 ps-3"
                            >Acessar</Dropdown.Toggle>

                            <Dropdown.Menu>
                              {platforms.map((platform: any) => (
                                <Dropdown.Item
                                  onClick={() => platformRedirect(row.original, platform)}
                                  key={platform.id}
                                >{platform.name}</Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>

                        <td style={{ verticalAlign: 'middle' }}>
                          <Password userId={row.original.id} password={row.original.password} disabled={!row.original?.email?.length} />
                        </td>

                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-end">
                            <Link to={`/gestores/${row.original.schoolId}/${row.original.id}/editar`} className="btn btn-sm btn-primary pe-3 ps-3">Editar</Link>
                            <Delete manager={row.original} onDelete={handleOnDelete} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              {schoolId > 0 ? 'Nenhum gestor encontrado.' : 'Selecione uma escola para que sejam exibidos os resultados.'}
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Managers
