import React, { useContext } from 'react'
import * as $App from '@services/App'
import { usePagination, useSortBy, useTable } from 'react-table'
import Table from '@components/Table/Table'
import { Spinner } from 'react-bootstrap'
import Pagination from '@components/Pagination/Pagination'
import useAppColumns from '@hooks/useAppColumns'
import App from './App/App'
import AppContext from '@contexts/App'

const Apps: React.FC<any> = () => {
  const { app, apps, setApps, canEdit, isLoading, setIsLoading, setCanEdit, onClick } = useContext(AppContext)

  const onDelete = (app: any) => {
    setIsLoading(true)
    setCanEdit(false)

    $App.destroy(app.id).then(({ data: apps }: any) => setApps(apps)).finally(() => {
      setIsLoading(false)
      setCanEdit(true)
    })
  }

  const columns = useAppColumns(onClick, onDelete, canEdit)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: apps,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>Chaves de App</h1>
        <button className="btn btn-primary pe-3 ps-3" onClick={() => onClick(null)} disabled={!canEdit}>Novo registro</button>
      </div>

      <div className="row">
        <div className={'col-12 ' + (!app ? '' : 'col-md-9')}>
          <div className="card">
            <div className="card-header">Apps</div>

            <div className="card-body p-0">
              {apps.length > 0 ? (
                <div className="table-responsive">
                  <Table
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                    headerGroups={headerGroups}
                    prepareRow={prepareRow}
                    isLoading={isLoading}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center p-3">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>

            {pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </div>

        <App />
      </div>
    </>
  )
}

export default Apps
