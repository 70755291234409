import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableAdminsColumns'
import Pagination from '@components/Pagination/Pagination'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import * as $Admin from '@services/Admin'
import IAdmin from '@interfaces/IAdmin'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Admins: React.FC<any> = () => {
  const [ admins, setAdmins ] = useState<IAdmin[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ search, setSearch ] = useState<string>('')

  const navigate = useNavigate()
  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    $Admin.all().then(({ data }: any) => setAdmins(data)).finally(() => setIsLoading(false))
  }, [])

  const data = useMemo(() => admins.filter((admin: IAdmin) => {
    let exists = true

    if (search.length > 0)
      exists = admin.id.toString().includes(search) ||
        admin.name.toLowerCase().includes(search.toLowerCase()) ||
        admin.email.toLowerCase().includes(search.toLowerCase())

    return exists
  }), [admins, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleDelete = (admin: IAdmin) => SweetAlert.fire({
    title: 'Deseja prosseguir?',
    text: `Deseja prosseguir com a exclusão do administrador ${admin.name}? Você não poderá voltar atrás dessa decisão.`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: getCssProperty('--secondary-color'),
    cancelButtonColor: getCssProperty('--primary-color'),
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((result: any) => {
    if (result.value && admin?.id) {
      setIsLoading(true)

      $Admin.remove(admin.id).then(() => {
        SweetAlert.fire({
          title: 'Sucesso!',
          text: 'Administrador excluído com sucesso!',
          icon: 'success'
        })
      }).catch(() => {
        SweetAlert.fire({
          title: 'Erro!',
          text: 'Erro ao excluir o administrador!',
          icon: 'error'
        })
      }).finally(() => {
        setIsLoading(false)
        SweetAlert.hideLoading()
      })
    }
  })

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-8 col-md-8">
          <div className="form-group">
            <label htmlFor="search">Pesquisar:</label>
            <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo ID, nome ou e-mail" onChange={e => setSearch(e.target.value)} disabled={isLoading} />
          </div>
        </div>

        <div className="col-4 col-md-4 d-flex justify-content-end align-items-end">
          <button className="btn btn-primary" onClick={() => navigate('/administradores/cadastrar')} disabled={isLoading}>Cadastrar</button>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Administradores</div>

        <div className="card-body p-0">
          {!isLoading ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 200 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {admins.length > 0 ? page.map((item: any, itemIndex: number) => {
                    prepareRow(item)
                    return (
                      <tr key={itemIndex}>
                        <td>
                          <span className="text-overflow">{item.values.id}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.values.name}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.values.email}</span>
                        </td>
                        <td className="d-flex">
                          <Link to={`/administradores/${item.values.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                          <button className="btn btn-sm ps-4 pe-4 btn-danger text-white ms-2" onClick={() => handleDelete(item.values)}>Excluir</button>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Admins
