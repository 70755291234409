import React, { useCallback, useContext } from 'react'
import Button from '@components/Button/Button'
import * as XLSX from 'xlsx'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import IAdminImportManagerRow from '@interfaces/IAdminImportManagerRow'
import AdminImportManagerContext from '@contexts/AdminImportManager'
import { random } from '@helpers/Utils'

const intValue = (value: string) => {
  if (isNaN(parseInt(value))) return 0
  return parseInt(value)
}

const stringValue = (value: string) => {
  if (value.trim().length === 0) return ''
  return value.toString()
}

const proccessRow = (row: any[], index: number, adminImportRows: IAdminImportManagerRow[], error: (text: string, index?: number | undefined) => void) => {
  const schoolId = intValue(row[0])
  const name = stringValue(row[1])
  const nickName = stringValue(row[2])
  const email = stringValue(row[3])
  const pass = row[4] != undefined ? stringValue(row[4]) : ''
  const roleId = intValue(row[5])
  const identifier = intValue(row[6])
  const degreeId = intValue(row[7])
  const className = row[8] != undefined ? stringValue(row[8]) : ''
  const platformId = intValue(row[9])

  if (!schoolId) {
    error('O ID da escola não foi informado.', index)
    return
  }

  adminImportRows.push({
    id: random(),
    schoolId: schoolId,
    name: name,
    nickname: nickName,
    email: email,
    password: pass,
    roleId: roleId,
    identifier: identifier,
    degreeId: degreeId,
    className: className,
    platformId: platformId,
    adminImportId: 0,
  })
}

const Upload: React.FC<any> = () => {
  const { isLoading, fileRef, file, setFile, setIsLoading, adminImportRows, pushAdmin, adminImport } = useContext(AdminImportManagerContext)

  const SweetAlert = withReactContent(Swal)

  const upload = useCallback(() => {
    if (!file)
      return

    setIsLoading(true)

    const error = (text: string, index?: number) => {
      setIsLoading(false)

      SweetAlert.fire({
        title: 'Erro!',
        text: index ? `${text}, na linha ${index + 1}` : text,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }

    const reader = new FileReader()

    reader.onload = (e: any) => {
      const workbook = XLSX.read(e.target.result, {
        type: 'binary'
      })

      const first_sheet_name = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[first_sheet_name]

      const rows = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      }) as string[][]

      rows.shift()

      if (!rows.length) {
        error('O arquivo não contém dados válidos!')
        return
      }

      const adminImportRows: IAdminImportManagerRow[] = []

      if (rows[0].length < 6) {
        error('O arquivo não contém todas as colunas necessárias!')
        return
      }

      for (let i = 0; i < rows.length; i++) {
        if (rows[i].length > 0 && !isNaN(parseInt(rows[i][0]))) {
          proccessRow(rows[i], i, adminImportRows, error)
        }
      }
      adminImport(file.name, adminImportRows.length)
      pushAdmin(adminImportRows).finally(() => setIsLoading(false))
    }

    reader.readAsBinaryString(file)
  }, [SweetAlert, adminImport, file, pushAdmin, setIsLoading])

  return (
    <div className="input-group">
      <input
        type="file"
        id="file-input"
        ref={fileRef}
        className="form-control"
        onChange={e => e.target.files?.length && setFile(e.target.files[0])}
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />

      <Button
        className="btn btn-primary pe-3 ps-3"
        loadingText="Aguarde..."
        isLoading={isLoading}
        disabled={isLoading || !file || adminImportRows.length > 0}
        onClick={upload}
      >Carregar planilha</Button>
    </div>
  )
}

export default Upload
