import React, { useEffect, useMemo, useState } from 'react'
import * as $ReaderTemplate from '@services/ReaderTemplate'
import { usePagination, useSortBy, useTable } from 'react-table'
import useReaderTemplateColumns from '@hooks/useReaderTemplateColumns'
import Table from '@components/Table/Table'
import { Spinner } from 'react-bootstrap'
import Pagination from '@components/Pagination/Pagination'

const ReaderTemplates: React.FC<any> = () => {
  const [ readerTemplates, setReaderTemplates ] = useState<any>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)

  useEffect(() => {
    $ReaderTemplate.all().then(({ data }) => setReaderTemplates(data)).finally(() => setIsLoading(false))
  }, [])

  const columns = useReaderTemplateColumns()

  const data = useMemo(() => readerTemplates, [readerTemplates])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  return (
    <>
      <div className="card">
        <div className="card-header">Templates de leitura</div>

        <div className="card-body p-0">
          {!isLoading ? (
            <div className="table-responsive">
              <Table
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                page={page}
                headerGroups={headerGroups}
                prepareRow={prepareRow}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ReaderTemplates
