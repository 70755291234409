import IUser from '@interfaces/IUser'
import IPlatformAssociationSelection from '@interfaces/PlatformAssociation/IPlatformAssociationSelection'
import axios from 'axios'

export const all = (schoolId: number, params: any = {}): Promise<any> => {
  return axios.get(`users/students/${schoolId}`, {
    params,
  })
}

export const years = (schoolId: number) : Promise<any> => {
  return axios.get(`users/students/${schoolId}/years`)
}

export const find = (schoolId: number, studentId: number, year: number): Promise<any> => {
  return axios.get(`users/students/${schoolId}/${studentId}`, {
    params: {
      year,
    },
  })
}

export const update = (schoolId: number, studentId: number, student: IUser): Promise<any> => {
  return axios.put(`users/students/${schoolId}/${studentId}`, student)
}

export const logs = (schoolId: number, studentId: number): Promise<any> => {
  return axios.get(`users/students/${schoolId}/${studentId}/logs`)
}

export const platformAssociations = (schoolId: number, studentId: number, params: any = {}): Promise<any> => {
  return axios.get(`users/students/${schoolId}/${studentId}/platform-associations`, {
    params,
  })
}

export const associate = (schoolId: number, studentId: number, data: IPlatformAssociationSelection): Promise<any> => {
  return axios.post(`users/students/${schoolId}/${studentId}/platform-associations`, data)
}

export const disassociate = (schoolId: number, studentId: number, data: IPlatformAssociationSelection): Promise<any> => {
  return axios.put(`users/students/${schoolId}/${studentId}/platform-associations`, data)
}
