import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as $Tools from '@services/Tools'
import * as $School from '@services/School'
import Pagination from '@components/Pagination/Pagination'
import { Modal, Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableEnvelopesColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import IEnvelope from '@interfaces/IEnvelope'
import { Search, Buttons } from './Envelope.styles'
import * as Utils from '@helpers/Utils'
import { getCssProperty } from '@helpers/Theme'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const Envelope: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ schoolId, setSchoolId ] = useState<number>(0)
  const [ envelopes, setEnvelopes ] = useState<IEnvelope[]>([])
  const [ envelopeId, setEnvelopeId ] = useState<number|null>(null)
  const [ assessmentId, setAssessmentId ] = useState<number|null>(null)
  const [ userCode, setUserCode ] = useState<number|null>(null)
  const [ finalized, setFinalized ] = useState<number|null>(null)
  const [ dateStart, setDateStart ] = useState<string|null>(null)
  const [ dateEnd, setDateEnd ] = useState<string|null>(null)

  const [ modalEnvelope, setModalEnvelope ] = useState<boolean>(false)
  const [ envelopeName, setEnvelopeName ] = useState<string>('')
  const [ file, setFile ] = useState<File|null>(null)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setIsLoading(true)

      $School.all().then(({ data }: any) => {
        setSchools(data)
      }).finally(() => setIsLoading(false))
    }
  }, [client])

  useEffect(() => {
    setEnvelopeName('')
    setFile(null)
  }, [modalEnvelope])

  const onSearch = useCallback(() => {
    setIsLoading(true)
    $Tools.envelope(schoolId, {
      assessmentId, envelopeId, userCode, dateStart, dateEnd, finalized
    }).then(({ data }: any) => setEnvelopes(data)).finally(() => setIsLoading(false))
  }, [assessmentId, dateEnd, dateStart, envelopeId, finalized, schoolId, userCode])

  const onStatus = useCallback((finalized: boolean, envelopeId: number) => {
    SweetAlert.fire({
      title: 'Envelope ' + (finalized ? '' : 'não ') + 'finalizado!',
      text: 'Deseja realmente ' + (finalized ? 'reabrir ' : 'finalizar ') + 'esse envelope?',
      icon: 'question',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--primary-color'),
      cancelButtonColor: getCssProperty('--secondary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value)
        SweetAlert.fire({
          title: 'Insira o motivo dessa alteração:',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: getCssProperty('--primary-color'),
          cancelButtonColor: getCssProperty('--secondary-color'),
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
        }).then((result: any) => {
          if (result.value) {
            SweetAlert.showLoading()
            $Tools.envelopeStatus({
              EnvelopeId: envelopeId,
              Message: result.value,
              Finalized: finalized ? 1 : 0
            }).then(() => {
              SweetAlert.fire({
                title: 'Sucesso!',
                text: 'Status do envelope atualizado com sucesso!',
                icon: 'success'
              })
            }).catch(() => {
              SweetAlert.fire({
                title: 'Erro!',
                text: 'Erro ao atualizar o status!',
                icon: 'error'
              })
            }).finally(() => {
              SweetAlert.hideLoading()
            })
          }
        })
    })
  }, [SweetAlert])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: envelopes,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const exportStudents = () => {
    const data = envelopes.map((envelope: IEnvelope) => ([
      envelope.id,
      envelope.finalized ? 'Sim' : 'Não',
      envelope.date?.toLocaleString() ?? 'Não possui',
      envelope.finalizedDate?.toLocaleString() ?? 'Não possui',
      envelope.firstUploadDate?.toLocaleString() ?? 'Não possui',
      envelope.lastUploadDate?.toLocaleString() ?? 'Não possui',
      envelope.schoolId,
      envelope.schoolName,
      envelope.assessmentId,
      envelope.assessmentName,
      envelope.evaluationId,
      envelope.evaluationName,
      envelope.image
    ] as string[]))

    data.unshift([
      'ID', 'Finalizado', 'Data criação', 'Data Finalização', 'Data primeiro upload', 'Data último upload',
      'ID escola', 'Nome escola', 'ID simulado', 'Nome simulado', 'ID prova', 'Nome prova', 'Imagem'
    ])

    Utils.exportXLSX(data, 'Lista Envelopes', 'Envelopes.xlsx')
  }

  const options = useMemo(() => [{
    value: 0,
    label: 'Todas'
  }, ...schools.map((school: ISchool) => ({
    value: school.id,
    label: school.name,
  }))], [schools])

  const formatDate = (date: any) => {
    return date ? new Date(date).toLocaleString() : 'Não possui'
  }

  const showImage = (image: string) => {
    SweetAlert.fire({
      imageUrl: image,
      imageAlt: 'Envelope',
      allowOutsideClick: false,
      confirmButtonColor: getCssProperty('--primary-color'),
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData()

    formData.append('EnvelopeName', envelopeName)

    if (file != null)
      formData.append('Image', file)

    SweetAlert.showLoading()

    $Tools.envelopeCreate(formData).then(() => {
      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Envelope criado com sucesso!',
        icon: 'success'
      })
    }).catch(e => SweetAlert.fire({
      title: 'Erro!',
      text: e.response.data.message ?? 'Erro ao criar envelope!',
      icon: 'error'
    })).finally(() => {
      SweetAlert.hideLoading()
      setModalEnvelope(false)
    })
  }

  return (
    <>
      <Modal
        show={modalEnvelope}
        onHide={() => setModalEnvelope(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar envelope</Modal.Title>
        </Modal.Header>
        <form encType="multipart/form-data" onSubmit={handleSubmit} onReset={() => setModalEnvelope(false)}>
          <Modal.Body className="border-bottom pb-0">
            <div className="row mb-3">
              <div className="d-flex flex-column text-center">
                <div className="p-3">
                  <label>Envelope</label>
                  <input className="form-control" type="text" onChange={(e) => setEnvelopeName(e.target.value)} />
                </div>
                <div className="p-3">
                  <label>Imagem</label>
                  <input className="form-control" type="file" accept="image/png, image/jpeg"
                    onChange={e => e.target.files?.length && setFile(e.target.files[0])}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-12 d-flex justify-content-between">
              <button className="btn btn-outline-primary" type="reset">Voltar</button>
              <button className="btn btn-primary" type="submit" disabled={!envelopeName.length}>Salvar</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <div className="row align-items-end">
        <div className="col-12 col-md-10">
          <Search>
            <div className="row">
              {options.length > 0 && (
                <div className="col-12 col-lg-6 form-group mb-3 schools">
                  <label htmlFor="search">Escola:</label>
                  <div className="select-schools">
                    <Select
                      type="primary"
                      onChange={handleSchoolIdChange}
                      defaultValue={0}
                      options={options}
                      isSearchable={true}
                      disabled={isLoading}
                      placeholder="Selecione uma escola"
                    />
                  </div>
                </div>
              )}

              <div className="col-4 col-lg-2 form-group mb-3">
                <label htmlFor="assessmentId">ID Simulado:</label>
                <input
                  type="number"
                  id="assessmentId"
                  name="assessmentId"
                  disabled={isLoading}
                  className="form-control"
                  onChange={e => setAssessmentId(e.target.value.length > 0 ? parseInt(e.target.value) : null)}
                />
              </div>

              <div className="col-4 col-lg-2 form-group mb-3">
                <label htmlFor="envelopeId">ID Envelope:</label>
                <input
                  type="number"
                  id="envelopeId"
                  name="envelopeId"
                  disabled={isLoading}
                  className="form-control"
                  onChange={e => setEnvelopeId(e.target.value.length > 0 ? parseInt(e.target.value) : null)}
                />
              </div>

              <div className="col-4 col-lg-2 form-group mb-3 userCode">
                <label htmlFor="userCode">RE:</label>
                <input
                  type="number"
                  id="userCode"
                  disabled={isLoading}
                  name="userCode"
                  className="form-control"
                  onChange={e => setUserCode(e.target.value.length > 0 ? parseInt(e.target.value) : null)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-lg-3 form-group mb-3">
                <label htmlFor="dateStart">Data criação (inicio):</label>
                <input
                  type="date"
                  id="dateStart"
                  disabled={isLoading}
                  name="dateStart"
                  className="form-control"
                  onChange={e => setDateStart(e.target.value.length > 0 ? e.target.value : null)}
                />
              </div>

              <div className="col-6 col-lg-3 form-group mb-3">
                <label htmlFor="dateEnd">Data criação (fim):</label>
                <input
                  type="date"
                  id="dateEnd"
                  name="dateEnd"
                  disabled={isLoading}
                  className="form-control"
                  onChange={e => setDateEnd(e.target.value.length > 0 ? (e.target.value) : null)}
                />
              </div>

              <div className="col-6 col-lg-2 form-group mb-3">
                <label htmlFor="isFinish">Finalizado:</label>
                <select id="isFinish" name="isFinish" className="form-control" onChange={e => setFinalized(e.target.value.length > 0 ? parseInt(e.target.value) : null)}>
                  <option value="2">Ambos</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>

              <Buttons className="col-6 col-lg-2">
                <button className="btn btn-primary mt-4 mb-3" disabled={isLoading} onClick={onSearch}>Pesquisar</button>
              </Buttons>
            </div>
          </Search>
        </div>

        <Buttons className="col-12 col-md-2 d-flex justify-content-end">
          <button className="btn btn-primary mb-3 me-2"
            disabled={modalEnvelope}
            onClick={() => setModalEnvelope(true)}
          >Cadastrar</button>
          <button className="btn btn-outline-primary mb-3" disabled={!envelopes.length} onClick={exportStudents}>Exportar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Envelopes</div>

        <div className="card-body p-0">
          {!isLoading ? (envelopes.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      <th style={{ width: 120 }}>Ações</th>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.length > 0 ? page.map((item: any, itemIndex: number) => {
                    prepareRow(item)
                    return (
                      <tr key={itemIndex}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-center">
                            <button className="btn btn-sm btn-primary me-2"
                              onClick={() => showImage(item.original.image)}
                              disabled={item.original.image ? false : true}
                            >Imagem</button>
                          </div>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.id}</span>
                        </td>
                        <td>
                          <span className="text-overflow">
                            <button className="btn btn-sm btn-outline-danger" onClick={() => onStatus(item.original.finalized, item.original.id)}>{item.original.finalized ? 'Sim' : 'Não'}</button>
                          </span>
                        </td>
                        <td>
                          <span className="text-overflow">{formatDate(item.original.date)}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{formatDate(item.original.finalizedDate)}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{formatDate(item.original.firstUploadDate)}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{formatDate(item.original.lastUploadDate)}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.schoolId}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.schoolName}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.assessmentId}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.assessmentName}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.evaluationId}</span>
                        </td>
                        <td>
                          <span className="text-overflow">{item.original.evaluationName}</span>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Selecione uma escola e/ou forneca alguma informação e clique em pesquisar para que os resultados sejam exibidos.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {envelopes.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Envelope
