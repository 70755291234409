import axios from 'axios'

export const migrationForce = (): Promise<any> => {
  return axios.post('tools/migration-force')
}

export const envelope = (schoolId: number, params: any = {}): Promise<any> => {
  return axios.get(`tools/envelope/${schoolId}`, {
    params,
  })
}

export const envelopeStatus = (params: any = {}): Promise<any> => {
  return axios.get('tools/envelope-status', {
    params,
  })
}

export const statusReportment = (assessmentId: number, params: any = {}): Promise<any> => {
  return axios.get(`tools/status-reportment/${assessmentId}`, {
    params,
  })
}

export const envelopeCreate = (params: any = {}): Promise<any> => {
  return axios.post('/tools/envelope-create', params)
}

export const audit = (answerCardAuditId: number): Promise<any> => {
  return axios.get(`tools/answer-card-audit/${answerCardAuditId}`)
}
