import React, { useState, useMemo, useContext } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableIReportmentAuditModalColumns'
import Pagination from '@components/Pagination/Pagination'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { getCssProperty } from '@helpers/Theme'
import ReportmentAuditContext from '@contexts/ReportmentAudit'

const ReportmentAudit: React.FC<any> = () => {
  const { showModal, setShowModal, isLoadingModal, reportment, reportments } = useContext(ReportmentAuditContext)
  const [ search, setSearch ] = useState<string>('')
  const [ typeCard, setTypeCard ] = useState<string>('')

  const data = useMemo(() => reportments.filter((reportment: any) => {
    let exists = true

    if (search.length > 0)
      exists = reportment.AnswerCardID.toString().includes(search) ||
        reportment.userCode?.toString().includes(search) ||
        reportment.StudentName?.toLowerCase().includes(search.toLowerCase())

    if (exists && typeCard.length > 0)
      exists = reportment.typeCard.toLowerCase().includes(typeCard.toLowerCase())

    return exists
  }), [reportments, search, typeCard])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const SweetAlert = withReactContent(Swal)

  const showImage = (image: string, user: string) => {
    SweetAlert.fire({
      imageUrl: image,
      imageAlt: 'Cartão',
      text: user.length ? 'Upload realizado: ' + user : '',
      confirmButtonColor: getCssProperty('--primary-color'),
    })
  }

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Relatório de { reportment.type === 1 ? 'auditorias' : 'erros'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !isLoadingModal ? (
          <>
            <div className="row align-items-end mb-3">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="search">Tipo:</label>
                  <select className="form-control" onChange={(e: any) => setTypeCard(e.target.value)}>
                    <option value="">Todos</option>
                    <option>C. Resposta</option>
                    <option>Redação</option>
                    { reportment.type === 2 ? <option>C. Não Reconhecido</option> : null }
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="search">Pesquisar:</label>
                  <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo ID, RE ou nome" onChange={e => setSearch(e.target.value)} disabled={isLoadingModal} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="table-responsive">
                <table {...getTableProps()} className="table table-default mb-0">
                  <thead>
                    {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                        {headerGroup.headers.map((column: any, columnIndex: number) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                            {column.render('Header')}
                            <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                          </th>
                        ))}
                        <th style={{ width: 200 }}>Imagem</th>
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    { page.length > 0 ? page.map((card: any, itemIndex: number) => {
                      prepareRow(card)
                      return (
                        <tr key={itemIndex}>
                          <td>{card.original.AnswerCardID}</td>
                          <td>{card.original.typeCard}</td>
                          <td>
                            {card.original.userCode ?? (
                              <span className="text-muted">N/A</span>
                            )}
                          </td>
                          <td>
                            {card.original.StudentName ?? (
                              <span className="text-muted">N/A</span>
                            )}
                          </td>
                          <td>{new Date(card.original.insertedDate).toLocaleString()}</td>
                          <td>{card.original.inserted ? 'Interno' : 'Externo'}</td>
                          <td>
                            <button className="btn btn-primary btn-sm pe-3 ps-3" onClick={() => showImage(card.original.imagePath, card.original.uploadUser)}>Imagem</button>
                          </td>
                        </tr>
                      )
                    }) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <span className="text-muted">Nenhum relatório encontrado</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ):
          (
            <div className="col">
              <div className="d-flex justify-content-center p-3">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          )}
      </Modal.Body>
      {pageCount > 1 && (
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default ReportmentAudit
