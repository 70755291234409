import React, { useContext } from 'react'
import { ReactComponent as Collapse } from '../../resources/svg/collapse.svg'
import PanelContext from '../../contexts/Panel'
import MenuItem from './MenuItem/MenuItem'

import { Aside, CollapseButton, SidebarContainer } from './Sidebar.styles'
import AuthContext from '@contexts/Auth'

import AccessLevelPermissionEnum from '@enums/AccessLevelPermission.enum'

const Sidebar: React.FC = () => {
  const { admin } = useContext(AuthContext)
  const { sidebarCollapsed, setSidebarCollapsed } = useContext(PanelContext)

  return (
    <SidebarContainer className={'sidebar col-12 ' + (sidebarCollapsed ? 'col-md-1 collapsed' : 'col-md-2')}>
      <Aside className="sidebar-sticky">
        <CollapseButton className="btn-collapse d-none d-md-block" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
          <Collapse />
        </CollapseButton>

        <MenuItem title="Dashboard" icon="panel" path="/" />
        <MenuItem title="Turmas" icon="classroom" path="/turmas" />
        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_STUDENTS) && (
          <MenuItem title="Alunos" icon="student" path="/alunos" />
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_TEACHERS) && (
          <MenuItem title="Professores" icon="teacher" path="/professores" />
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_REVISORS) && (
          <MenuItem title="Corretores" icon="revisor" path="/corretores" />
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_MANAGERS) && (
          <MenuItem title="Gestores" icon="manager" path="/gestores" />
        )}

        <MenuItem title="Associação em massa" icon="inscription" path="/associacao-em-massa" />
        <MenuItem title="Turmas em massa" icon="inscription" path="/turmas-em-massa" />
        <MenuItem title="E-mail Gestores" icon="inscription" path="/email-gestores" />
        <MenuItem title="Gerar Boletim" icon="studentGrades" path="/gerar-boletim" />

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_PERMISSIONS) && (
          <>
            <MenuItem title="Permissões" icon="toManage" path="/permissoes" />
            <MenuItem title="Chaves de APP" icon="toManage" path="/chaves-de-app" />
          </>
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_REPORTMENTS) && (
          <MenuItem
            title="Relatórios"
            icon="product"
            path={[
              '/relatorios',
              '/relatorios/categorias',
              '/relatorios/autorizacoes',
            ]}
          >
            <MenuItem title="Relatórios" path="/relatorios" />
            <MenuItem title="Categorias" path="/relatorios/categorias" />
            <MenuItem title="Autorizações" path="/relatorios/autorizacoes" />
          </MenuItem>
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ACCESSLEVEL) && (
          <MenuItem title="Níveis de acesso" icon="user" path="/niveis-de-acesso" />
        )}

        {admin.permissions.includes(AccessLevelPermissionEnum.VIEW_ADMINS) && (
          <MenuItem title="Administradores" icon="user" path="/administradores" />
        )}

        <MenuItem title="Templates de leitura" icon="user" path="/templates-de-leitura" />
        <MenuItem title="Ferramentas" icon="engagement" path="/ferramentas" />
        <MenuItem title="Envelopes" icon="evaluationsAndResolutions" path="/envelopes" />
        <MenuItem title="Relatório de auditorias e erros" icon="product" path="/relatorio-auditoria-erros" />
        <MenuItem title="Relatório de Resp. Inscrição" icon="product" path="/relatorio-responsavel-inscricao" />
        <MenuItem title="Importar em massa" icon="inscription" path="/importar-usuarios" />
      </Aside>
    </SidebarContainer>
  )
}

export default Sidebar
