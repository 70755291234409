import React, { useEffect, useContext, useMemo, useState } from 'react'
import AuthContext from '@contexts/Auth'
import * as $School from '@services/School'
import ISchool from '@interfaces/ISchool'
import Select from '@components/Select/Select'
import { Buttons } from './ReportmentInscriptions.styles'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableReportmentInscriptionColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import Pagination from '@components/Pagination/Pagination'
import * as Utils from '@helpers/Utils'
import * as $ReportmentInscription from '@services/ReportmentInscription'
import IProduct from '@interfaces/IProduct'
import IUser from '@interfaces/IUser'

const ReportmentInscriptions: React.FC<any> = () => {
  const { client } = useContext(AuthContext)
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ schoolId, setSchoolId ] = useState<number>(0)
  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)
  const [ assessments, setAssessments ] = useState<IProduct[]>([])
  const [ assessmentId, setAssessmentId ] = useState<number>(0)
  const handleAssessmentIdChange = ({ value: assessmentId }: any) => setAssessmentId(assessmentId)
  const [ search, setSearch ] = useState<string>('')
  const [ students, setStudents ] = useState<IUser[]>([])

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setIsLoading(true)

      $School.all().then(({ data }: any) => {
        setSchools(data)
      }).finally(() => setIsLoading(false))
    }
  }, [client])

  useEffect(() => {
    if (schoolId > 0) {
      setAssessmentId(0)
      setIsLoading(true)

      $ReportmentInscription.find(schoolId).then(({ data }: any) => {
        setAssessments(data)
      }).finally(() => setIsLoading(false))
    }
  }, [schoolId])

  useEffect(() => {
    if (schoolId > 0 && assessmentId > 0) {
      setIsLoading(true)
      $ReportmentInscription.get(schoolId, assessmentId).then(({ data }: any) => {
        setStudents(data)
      }).finally(() => setIsLoading(false))
    }
  }, [schoolId, assessmentId])

  const options = useMemo(() => [
    ...schools.map((school: ISchool) => ({
      value: school.id,
      label: school.name,
    }))], [schools])

  const assessmentOptions = useMemo(() => [
    ...assessments.map((assessment: IProduct) => ({
      value: assessment.id,
      label: assessment.name,
    }))], [assessments])

  const data = useMemo(() => students.filter((student: IUser) => {
    let exists = true

    if (search.length > 0)
      exists = student.id.toString().includes(search) ||
      student.name.toLowerCase().includes(search.toLowerCase()) ||
      student.email.toLowerCase().includes(search.toLowerCase()) ||
      student.logUserName.toLowerCase().includes(search)

    return exists
  }), [students, search])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const exportStudents = () => {
    const excel = data.map((student: IUser) => ([
      student.id,
      student.name,
      student.email,
      student.logUserName,
    ] as string[]))

    excel.unshift([
      'ID', 'Nome', 'E-mail', 'Nome atualizador',
    ])

    Utils.exportXLSX(excel, 'Lista de Usuários', 'Usuarios.xlsx')
  }

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-lg-10">
          <div className="row">
            {options.length > 0 && (
              <div className="col-12 col-md-4 form-group mb-3 schools">
                <label htmlFor="search">Escola:</label>
                <div className="select-schools">
                  <Select
                    type="primary"
                    onChange={handleSchoolIdChange}
                    defaultValue={0}
                    options={options}
                    isSearchable={true}
                    disabled={isLoading}
                    placeholder="Selecione uma escola"
                  />
                </div>
              </div>
            )}
            {schoolId != 0 && assessmentOptions.length > 0 && (
              <div className="col-12 col-md-4 form-group mb-3 schools">
                <label htmlFor="search">Simulado:</label>
                <div className="select-schools">
                  <Select
                    type="primary"
                    onChange={handleAssessmentIdChange}
                    defaultValue={0}
                    options={assessmentOptions}
                    isSearchable={true}
                    disabled={isLoading}
                    placeholder="Selecione um simulado"
                  />
                </div>
              </div>
            )}
            <div className="col-12 col-md-4 mb-3">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control"
                  placeholder="Pesquise pelo ID, nome ou e-mail"
                  onChange={e => setSearch(e.target.value)}
                  disabled={schoolId == 0 || isLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <Buttons className="col-12 col-lg-2 mb-3 d-flex justify-content-end">
          <button className="btn btn-outline-primary" disabled={!data.length || isLoading} onClick={exportStudents}>Exportar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Relatório de responsável por inscrição</div>
        <div className="card-body p-0">
          {!isLoading ? (data.length > 0 ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="bg-light text-center p-3">
              Selecione uma escola e um simulado.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {students.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ReportmentInscriptions
