import * as XLSX from 'xlsx'

export const exportXLSX = (data: string[][], sheet: string, filename: string) : void => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, ws, sheet)
  XLSX.writeFile(wb, filename)
}

export const random = (): number => parseInt((Math.random() * 100000).toFixed(0))

export const generateToken = (size: number): string => new Array(size).join().replace(/(.|$)/g, () => ((Math.random()*36)|0).toString(36)[Math.random()<.5?'toString':'toUpperCase']())
